import React from "react"
import { graphql, StaticQuery } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../utils/normalize.css"
import "../utils/css/screen.css"

const AboutPage = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout title={siteTitle}>
      <SEO title="About" />
      <div id="swup" className="about-container">
        <div className="about-feed public">
          <article className="about-card false post with-image">
            <div className="about-card-content"></div>
          </article>
          <article className="about-card false post no-image">
            <div className="about-card-content">
              <p className="about-card-copy">
                Claudia du Lièvre’s experiment with photography began when,
                looking for a skateboard, she found her grandfather’s Minolta
                camera. She started taking photographs of her friends and close
                surroundings. Intimacy and self expression remained her main
                themes.
              </p>
              <p className="about-card-copy">
                Currently based in London, Claudia focuses on fashion and
                portraiture, oftentimes with dramatic use of color. Through her
                photographs she aims to transcribe an honest and intuitive
                apprehension of her subject. Done via controlled and intentional
                pose, she reaches for the shining authenticity of a candid
                scene. Her favourite pieces are the stolen moments in which her
                subject fails to play their part, and is rather carried by their
                honest movements and emotions.
              </p>
            </div>
          </article>
        </div>
      </div>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <AboutPage location={props.location} data={data} {...props} />
    )}
  />
)
